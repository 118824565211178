import axios from 'axios'
import { CLIENT_API } from '@/config/api'

export const state = () => ({
  operationsClient: null,
  operationsSelected: null,
  subscribeManualPacResult: null,
})

export const actions = {
  savingOperationsSelected({ commit }, info) {
    try {
      commit('setOperationsSelected', info)
    } catch (error) {
      commit('setOperationsSelected', error)
    }
  },

  async searchOperationsClient({ commit }, { tokenCaptcha, dni }) {
    try {
      const headers = {
        headers: {
          'x-api-token': process.env.apiToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      const body = {
        app_name: 'Arc',
        client_dni: dni,
        token: tokenCaptcha,
      }
      const { data } = await axios.post(CLIENT_API.SEARCH_OPERATIONS_BY_RUT, body, headers)

      commit('setOperationsClient', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
      commit('setOperationsClient', null)
      return null
    }
  },

  setOperationsClient({ commit }, info) {
    commit('setOperationsClient', info)
  },

  async subscribeManualPac({ commit }, body) {
    try {
      const headers = {
        headers: {
          'x-api-token': process.env.apiToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      const { data } = await axios.post(CLIENT_API.SUBSCRIBE_MANUAL_PAC, body, headers)
      commit('subscribeManualPacResult', data)
      return data
    } catch (error) {
      this.$sentry.captureException(error)
      commit('subscribeManualPacResult', null)
      return null
    }
  },
}

export const getters = {
  getOperationsClient(s) {
    return s.operationsClient
  },

  getOperationsSelected(s) {
    return s.operationsSelected
  },

  getSubscribeManualPacResult(s) {
    return s.subscribeManualPacResult
  },
}

export const mutations = {
  setOperationsClient(s, operationsClient) {
    s.operationsClient = operationsClient
  },

  setOperationsSelected(s, operationsSelected) {
    s.operationsSelected = operationsSelected
  },

  subscribeManualPacResult(s, subscribeManualPacResult) {
    s.subscribeManualPacResult = subscribeManualPacResult
  },
}
