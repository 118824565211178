import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default ({ app }) => {
  app.i18n = new VueI18n({
    locale: process.env.locale,
    messages: {
      en: require('@/locales/en.json'),
      'es-cl': require('@/locales/es-cl.json'),
    },
  })
}
