import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _01bb4752 = () => interopDefault(import('../pages/pac/index.vue' /* webpackChunkName: "pages/pac/index" */))
const _0200ef89 = () => interopDefault(import('../pages/pac/manual-subscription.vue' /* webpackChunkName: "pages/pac/manual-subscription" */))
const _0751e37e = () => interopDefault(import('../pages/pac/operations-to-subscribe.vue' /* webpackChunkName: "pages/pac/operations-to-subscribe" */))
const _6344abc8 = () => interopDefault(import('../pages/pac/success.vue' /* webpackChunkName: "pages/pac/success" */))
const _d121a698 = () => interopDefault(import('../pages/payment/down-payments/index.vue' /* webpackChunkName: "pages/payment/down-payments/index" */))
const _3335d3fe = () => interopDefault(import('../pages/payment/error.vue' /* webpackChunkName: "pages/payment/error" */))
const _e7680e18 = () => interopDefault(import('../pages/payment/installments/index.vue' /* webpackChunkName: "pages/payment/installments/index" */))
const _6ae30c84 = () => interopDefault(import('../pages/payment/preorders/index.vue' /* webpackChunkName: "pages/payment/preorders/index" */))
const _c93473e8 = () => interopDefault(import('../pages/payment/select-payment.vue' /* webpackChunkName: "pages/payment/select-payment" */))
const _1a083a3c = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _28bc9547 = () => interopDefault(import('../pages/result/error/index.vue' /* webpackChunkName: "pages/result/error/index" */))
const _187472a3 = () => interopDefault(import('../pages/result/not-supported-debts.vue' /* webpackChunkName: "pages/result/not-supported-debts" */))
const _6c090192 = () => interopDefault(import('../pages/result/without-debts.vue' /* webpackChunkName: "pages/result/without-debts" */))
const _bee9d728 = () => interopDefault(import('../pages/payment/installments/list.vue' /* webpackChunkName: "pages/payment/installments/list" */))
const _7af7a0ba = () => interopDefault(import('../pages/payment/installments/partial.vue' /* webpackChunkName: "pages/payment/installments/partial" */))
const _a0096248 = () => interopDefault(import('../pages/payment/preorders/list.vue' /* webpackChunkName: "pages/payment/preorders/list" */))
const _57a49f33 = () => interopDefault(import('../pages/payment/preorders/partial.vue' /* webpackChunkName: "pages/payment/preorders/partial" */))
const _b601cad8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/pac",
    component: _01bb4752,
    name: "pac"
  }, {
    path: "/pac/manual-subscription",
    component: _0200ef89,
    name: "pac-manual-subscription"
  }, {
    path: "/pac/operations-to-subscribe",
    component: _0751e37e,
    name: "pac-operations-to-subscribe"
  }, {
    path: "/pac/success",
    component: _6344abc8,
    name: "pac-success"
  }, {
    path: "/payment/down-payments",
    component: _d121a698,
    name: "payment-down-payments"
  }, {
    path: "/payment/error",
    component: _3335d3fe,
    name: "payment-error"
  }, {
    path: "/payment/installments",
    component: _e7680e18,
    name: "payment-installments"
  }, {
    path: "/payment/preorders",
    component: _6ae30c84,
    name: "payment-preorders"
  }, {
    path: "/payment/select-payment",
    component: _c93473e8,
    name: "payment-select-payment"
  }, {
    path: "/payment/success",
    component: _1a083a3c,
    name: "payment-success"
  }, {
    path: "/result/error",
    component: _28bc9547,
    name: "result-error"
  }, {
    path: "/result/not-supported-debts",
    component: _187472a3,
    name: "result-not-supported-debts"
  }, {
    path: "/result/without-debts",
    component: _6c090192,
    name: "result-without-debts"
  }, {
    path: "/payment/installments/list",
    component: _bee9d728,
    name: "payment-installments-list"
  }, {
    path: "/payment/installments/partial",
    component: _7af7a0ba,
    name: "payment-installments-partial"
  }, {
    path: "/payment/preorders/list",
    component: _a0096248,
    name: "payment-preorders-list"
  }, {
    path: "/payment/preorders/partial",
    component: _57a49f33,
    name: "payment-preorders-partial"
  }, {
    path: "/",
    component: _b601cad8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
