<template>
  <b-container fluid>
    <Navbar class="shadow-sm" />
    <b-row align-h="center">
      <b-col xl="3" lg="5" md="8" cols="12" class="pt-5">
        <b-card class="border-0 card py-2">
          <Nuxt />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Navbar from '@/components/shared/Navbar'
export default {
  components: {
    Navbar,
  },
}
</script>
