export default {
  name: 'navbar',
  data() {
    return {
      companyUrl: process.env.companyUrl,
      windowsWidth: window.innerWidth,
    }
  },
  methods: {
    handleResize() {
      this.windowsWidth = window.innerWidth
    },
    checkWindowsSize() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    assignLogo() {
      return !this.enterRut || this.windowsWidth <= 580 ? 'logo-white' : 'logo'
    },
  },
  created() {
    this.checkWindowsSize()
  },
  props: {
    enterRut: {
      type: Boolean,
      default: false,
    },
  },
}
