<template>
  <b-container fluid>
    <Navbar class="shadow-sm" />
    <b-row class="justify-content-center">
      <b-col class="pt-5">
        <Nuxt />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Navbar from '@/components/shared/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
