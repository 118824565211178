export default function ({ route, store, redirect }) {
  if (route.name === 'payment-success' || route.name === 'payment-error') {
    return
  }

  function isEnter(currentRoute) {
    if (currentRoute.name === null) {
      return true
    }
    return currentRoute?.name.includes('payment')
  }

  function validateRedirect(routeData) {
    let debtsInfo = store.getters['client/getDebtsInfo']

    if (debtsInfo === null && isEnter(routeData)) {
      return redirect('/')
    }
  }

  try {
    if (route.path !== '/') validateRedirect(route)
  } catch (error) {
    this.$sentry.captureException(error)
    redirect('/')
  }
}
