const CLIENT_API = {
  GET_BANKS: `${process.env.clientUrl}/banks/list`,
  GET_FINTOCS_BANKS: `${process.env.clientUrl}/banks/fintoc-list`,
  GET_MANUAL_BANKS: `${process.env.clientUrl}/banks/manual-list`,
  SUBSCRIBE_MANUAL_PAC: `${process.env.clientUrl}/pac/manual-subscription`,
  GET_DEBTS_INFO: `${process.env.clientUrl}/search`,
  POST_PAYMENT: `${process.env.clientUrl}/pay`,
  SEARCH_OPERATIONS_BY_RUT: `${process.env.clientUrl}/pac/search-client-operations`,
  SUBSCRIPTION_INTENT: `${process.env.clientUrl}/pac/subscription-intent`,
  WEBHOOK: `${process.env.clientUrl}/pac/webhook-event`,
}

export { CLIENT_API }
