import { CLIENT_API } from '@/config/api'
import axios from 'axios'

export const state = () => ({
  paymentCheck: false,
  debtsInfo: null,
  banksInfo: null,
  bankSelected: null,
  payment: null,
})

export const actions = {
  async getDebtsInfo({ commit }, { tokenCaptcha, dni }) {
    try {
      const headers = {
        headers: {
          'x-api-token': process.env.apiToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      const body = {
        app_name: 'Arc',
        client_dni: dni,
        token: tokenCaptcha,
      }
      const { data } = await axios.post(CLIENT_API.GET_DEBTS_INFO, body, headers)

      commit('setDebtsInfo', data)
    } catch (error) {
      this.$sentry.captureException(error)
      commit('setDebtsInfo', error)
    }
  },

  async getBanksInfo({ commit }) {
    try {
      const headers = {
        headers: {
          'x-api-token': process.env.apiToken,
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.get(CLIENT_API.GET_BANKS, headers)

      commit('setBanksInfo', data)
    } catch (error) {
      this.$sentry.captureException(error)
      commit('setBanksInfo', error)
    }
  },

  async sendPayment({ commit }, body) {
    try {
      const headers = {
        headers: {
          'x-api-token': process.env.apiToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      const { data } = await axios.post(CLIENT_API.POST_PAYMENT, body, headers)
      commit('setPayment', data)
    } catch (error) {
      this.$sentry.captureException(error)
      commit('setPayment', null)
    }
  },

  async modalPaymentOpen({ commit }) {
    try {
      commit('setPaymentChek', true)
    } catch (error) {
      commit('setPaymentChek', false)
    }
  },

  async savingBankInfo({ commit }, info) {
    try {
      commit('setBankSelected', info)
    } catch (error) {
      commit('setBankSelected', error)
    }
  },

  async saveDebtsSelected({ commit }, info) {
    try {
      commit('setDebtsSelected', info)
    } catch (error) {
      commit('setDebtsSelected', error)
    }
  },
}

export const getters = {
  getDebtsInfo(s) {
    return s.debtsInfo
  },
  getDebtsSelected(s) {
    return s.debtsSelected
  },
  getBanksInfo(s) {
    return s.banksInfo
  },
  getBankSelectedInfo(s) {
    return s.bankSelected
  },
  getPaymentData(s) {
    return s.payment
  },
  getPaymentCheck(s) {
    return s.paymentCheck
  },
}

export const mutations = {
  setDebtsInfo(s, debtsInfo) {
    s.debtsInfo = debtsInfo
  },
  setDebtsSelected(s, debtsSelected) {
    s.debtsSelected = debtsSelected
  },
  setBanksInfo(s, banksInfo) {
    s.banksInfo = banksInfo
  },
  setBankSelected(s, bankSelected) {
    s.bankSelected = bankSelected
  },
  setPayment(s, payment) {
    s.payment = payment
  },
  setPaymentChek(s, paymentCheck) {
    s.paymentCheck = paymentCheck
  },
}
